import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactForm from "components/ContactForm"

import {
  Content,
  Video,
  Mission,
  GreenArea,
  Baner,
} from "page_components/developer"

const Support = ({ location }) => {
  const title = "Trust Investment"

  return (
    <Layout
      location={location}
      seo={{
        title: "Sprawdzony deweloper",
        description:
          "Liczne realizacje, zadowoleni Klienci, projekty mieszkaniowe i komercyjne w całej Polsce. Poznaj Trust Investment S.A. - sprawdzonego dewelopera.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Video />
      <Mission />
      <GreenArea />
      <Baner />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export default Support
